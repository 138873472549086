<template>
  <div id="windowElevator" v-title :data-title="$t('i18n.productDistribution')">
    <div id="jlMap"></div>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import BMap from "BMap";
export default {
  name: "WindowElevator",
  setup(props, { emit }) {
    emit("public_header", false);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const router = useRouter();
    const state = reactive({
      paging: {
        pageNumber: 1,
        pageSize: 1000,
      },
    });

    const initMap = () => {
      var map = (state.jlMap = new BMap.Map("jlMap"));
      map.centerAndZoom(new BMap.Point(proxy.$point[0], proxy.$point[1]), 5);
      map.enableScrollWheelZoom(true);
      map.enableDragging();
      map.setMapStyleV2({ styleId: "9be68db667b0b88e5e1c2a7f3dbe3abf" });
    };

    const initData = () => {
      getNotFaultEle();
      getHandleEleMap(20);
      getHandleEleMap(10);
    };

    const getNotFaultEle = async () => {
      var allPoints = [];
      var points = [];
      var options = {
        // size: BMAP_POINT_SIZE_SMALL,
        shape: "BMAP_POINT_SHAPE_STAR",
        color: "green",
      };
      
      const { data } = await proxy.$api.monitor.eleNotError(state.paging);
      // 判断当前浏览器是否支持绘制海量点
      if (document.createElement("canvas").getContext) {
        points = data.records;

        allPoints= allPoints.concat(points);
        console.log(allPoints.length)
        // // 初始化PointCollection
        var pointCollection = new BMap.PointCollection(allPoints, options);
        // 添加点击事件
        pointCollection.addEventListener("click", function (e) {
          proxy.$api.monitor.eleNotErrorById(e.point.id).then((r) => {
            let info = r.data[0];
            var opts = {
              width: 200,
              height: 100,
              title:
                t("i18n.number") +
                "：<a class='eleNumber' data-sid=" +
                info.id +
                ">" +
                info.number +
                "</a>",
            };
            var infoWindow = new BMap.InfoWindow(
              t("i18n.address") + "：" + info.alias,
              opts
            );
            var pt = new BMap.Point(info.lng, info.lat);
            state.jlMap.openInfoWindow(infoWindow, pt);
            if (!infoWindow.isOpen()) {
              //如果没有打开，则监听打开事件，获取按钮，添加事件
              infoWindow.addEventListener("open", function () {
                infoWindowOnClick();
              });
            } else {
              //如果已经打开，直接获取按钮，添加事件
              infoWindowOnClick();
            }
          });
        });
        state.jlMap.addOverlay(pointCollection);
        if (state.paging.pageNumber < data.pages) {
          await requestPoints(pointCollection,allPoints);
        }
        
      } else {
        alert("请在chrome、safari、IE8+以上浏览器查看本示例");
      }
    };

    const requestPoints = async (pc, points) => {
      
          // pc.setPoints(points);
          console.log(points.length)
          
          var {data:newData} = await proxy.$api.monitor.eleNotError(state.paging);
          points = points.concat(newData.records);
          pc.clear()
          pc.setPoints(points)
        if (state.paging.pageNumber < newData.pages) {  
          
          state.paging.pageNumber++;
          await requestPoints(pc,points);
        }
    }

    

    const getHandleEleMap = async (type) => {
      // 处理中20，未处理10
      const { data } = await proxy.$api.monitor.eleAllError(type);

      // console.log("points:",data);
      if (document.createElement("canvas").getContext) {
        var points = data;
        var options =
          type == 10
            ? {
                // size: BMAP_POINT_SIZE_SMALL,
                // shape: "BMAP_POINT_SHAPE_STAR",
                color: "red",
              }
            : {
                color: "orange",
              };
        // 初始化PointCollection
        var pointCollection = new BMap.PointCollection(points, options);
        // 添加点击事件
        pointCollection.addEventListener("click", function (e) {
          proxy.$api.monitor.eleNotErrorById(e.point.id).then((r) => {
            let info = r.data[0];
            var opts = {
              width: 200,
              height: 100,
              title:
                t("i18n.number") +
                "：<a class='eleNumber' data-sid=" +
                info.id +
                ">" +
                info.number +
                "</a>",
            };
            var infoWindow = new BMap.InfoWindow(
              t("i18n.address") + "：" + info.alias,
              opts
            );
            var pt = new BMap.Point(info.lng, info.lat);
            state.jlMap.openInfoWindow(infoWindow, pt);
            if (!infoWindow.isOpen()) {
              //如果没有打开，则监听打开事件，获取按钮，添加事件
              infoWindow.addEventListener("open", function () {
                infoWindowOnClick();
              });
            } else {
              //如果已经打开，直接获取按钮，添加事件
              infoWindowOnClick();
            }
          });
        });
        state.jlMap.addOverlay(pointCollection);
        if (state.paging.pageNumber < data.pages) {
          state.paging.pageNumber++;
          getNotFaultEle();
        }
      } else {
        alert("请在chrome、safari、IE8+以上浏览器查看本示例");
      }
      // data.map((point) => {
      //   // 创建图标
      //   // var marker = new BMap.Label("<div class=" + icon + "></div>", {
      //   //   position: point,
      //   // });
      //   const myIcon = type == 10 ?

      //    new BMap.Icon("http://api.map.baidu.com/img/markers.png", new BMap.Size(23,25),{
      //     offset: new BMap.Size(10,25),
      //     imageOffset: new BMap.Size(0,0 - 11 * 25)
      //   }):
      //   new BMap.Icon("http://api.map.baidu.com/img/markers.png", new BMap.Size(23,25),{
      //     offset: new BMap.Size(10,25),
      //     imageOffset: new BMap.Size(0,0 - 12 * 25)
      //   })
      //   ;
      //   var marker = new BMap.Marker(point,{icon:myIcon});
      //   // marker.setStyle({
      //   //   border: "none",
      //   //   background: "transparent",
      //   // });
      //   // 将标注添加到地图
      //   state.jlMap.addOverlay(marker);
      //   // marker.addEventListener("click", function (e) {
      //   //   let info = e.target.point;
      //   //   var opts = {
      //   //     width: 200,
      //   //     height: 100,
      //   //     title:
      //   //       t("i18n.number") +
      //   //       "：<a class='eleNumber' data-sid=" +
      //   //       info.id +
      //   //       ">" +
      //   //       info.number +
      //   //       "</a>",
      //   //   };
      //   //   var infoWindow = new BMap.InfoWindow(
      //   //     t("i18n.address") + "：" + info.alias,
      //   //     opts
      //   //   );
      //   //   var pt = new BMap.Point(e.point.lng, e.point.lat);
      //   //   state.jlMap.openInfoWindow(infoWindow, pt);
      //   //   if (!infoWindow.isOpen()) {
      //   //     //如果没有打开，则监听打开事件，获取按钮，添加事件
      //   //     infoWindow.addEventListener("open", function () {
      //   //       infoWindowOnClick();
      //   //     });
      //   //   } else {
      //   //     //如果已经打开，直接获取按钮，添加事件
      //   //     infoWindowOnClick();
      //   //   }
      //   // });
      // });
    };

    const infoWindowOnClick = () => {
      var btn = document.getElementsByClassName("eleNumber");
      for (var i = 0; i < btn.length; i++) {
        btn[i].onclick = function (e) {
          let id = e.target.dataset.sid;
          enterDetail(id);
        };
      }
    };

    const enterDetail = (id) => {
      let routeData = router.resolve({
        path: "/realTime/detail",
        query: { id: id },
      });
      window.open(routeData.href, "_blank");
    };

    onMounted(() => {
      initMap();
      initData();
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss">
#windowElevator {
  width: 100%;
  height: 100%;
  #jlMap {
    height: 100%;
    .redDot,
    .yellowDot {
      width: 10px;
      height: 10px;
      border: none;
      cursor: pointer;
      border-radius: 50%;
    }
    .redDot {
      background: #d02c2f;
      animation: scale 2s infinite;
    }
    .yellowDot {
      background: #fdbf01;
    }
    @keyframes scale {
      @for $i from 0 through 10 {
        #{$i * 10%} {
          transform: scale($i / 10);
          opacity: $i / 10;
        }
      }
    }
  }
}
</style>
